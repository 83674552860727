import React,{ useState } from "react";
import classNames from "classnames";
import Layout from "../components/wrappers/layout"
import SiteHeaderAlt from "../components/wrappers/site-header-alt"
import SEO from "../components/seo"
import { domain, scrollToSection } from "../utils"
import Header from '../components/header';
import CaseList from '../components/case-list';
import { graphql } from 'gatsby';
import { getSinglePage } from "../utils";
import { useEffect } from "react"
import { useContext } from "react"
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider"
import Link from "../components/link";
import BlogList from "../components/blog-list";
import box_view from "../images/box-view.svg"; 
import list_view from "../images/list-view.svg"; 
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const Cases = props => {
  const { footers, headers, caseStudies, casesPage, casesOverview, tags, general } = props.data.strapi;
  const { generalBackgroundColorDarkMode, generalBackgroundColorLightMode  , fontColorDarkMode, fontColorLightMode, hashtagsBackgroundColor, hashtagsBackgroundColorDarkMode, clientLogosColorDarkMode, clientLogosColorLightMode, pageTransitionBgColorLightMode, pageTransitionBgColorDarkMode } = general; 
  const { copyright } = casesOverview;
  const language = props.pageContext.language
  const header = headers && headers.find((header)=>  header.language === language ); 
  const footer = footers && footers.find((footer)=>  footer.language === language ) || {}; 
  
  // getting  page first H1 to set as meta title and description if not set in strapi .
  const heading = casesOverview.heading; 
  const seoTitle = casesPage && casesPage.seo && casesPage.seo.metaData.metaTitle || "Case studies"+" "+ heading ? heading : "Cases";
  const seoDescription = casesPage && casesPage.seo && casesPage.seo.metaData.metaDescription || "Case studies" + " "+ heading ? heading : "Cases";
  const seoKeywords = casesPage && casesPage.seo && casesPage.seo?.metaData?.keywords;
  const dispatch = useContext(GlobalDispatchContext)
  const filteredCasesStudies = caseStudies.filter((caseStudy) => !caseStudy.inactive && caseStudy.showCaseOnCasesOverview); 
  const webpages = casesOverview && casesOverview.webPages || {}; 
  const allPages = props.data?.allSitePage?.nodes; 
  const redirectTo = getSinglePage(webpages, allPages); 
  const state = useContext(GlobalStateContext);
  const breakpoints= useBreakpoint(); 
  const isSubOpen = state?.siteSubNav;
  const [tagsList, setTagsList] = useState([]); 
  const [activeIndex, setActiveIndex] = useState(0); 
  const [scrollPos, setScrollPos] = useState(0);
  const [activeDisplay, setActiveDisplay] = useState("");
  const darkMode = state.darkModePreference === 'moon'; 
  const imageBorderRadiusForServices = general.imageBorderRadiusForServices; 
  const sectionBackgroundColor = darkMode ? generalBackgroundColorDarkMode : generalBackgroundColorLightMode; 
  const websiteFontColor = darkMode ? fontColorDarkMode : fontColorLightMode; 
  const logosBgColor = darkMode ? clientLogosColorDarkMode : clientLogosColorLightMode; 
  const displayedHashtagBgColor = darkMode ? hashtagsBackgroundColorDarkMode : hashtagsBackgroundColor;
  const pageTransitionBgColor = darkMode ? pageTransitionBgColorDarkMode : pageTransitionBgColorLightMode; 
  if (copyright) {
    footer.copyright = copyright;
  }

  const handleClick = (state) => {
    const isBrowser = typeof window !== "undefined";
    document.body.scrollTo(0,0)
    if (isBrowser) {
      localStorage.setItem("preferredView", state)
    }
    setActiveDisplay(state)
  }

  useEffect(() => {
    const scrollTo = state.scrollToSection; 
    const isBrowser = typeof window !== "undefined";

    if (isBrowser) {
      const preferredView = window.localStorage.getItem("preferredView"); 
      if(preferredView) {
        setActiveDisplay(preferredView)
      } else {
        setActiveDisplay("")
      }
    }
    if(scrollTo) {
      setTimeout(() => {
        scrollToSection(scrollTo);
      }, 300); 
    }
    const id = "case-studies"; 
    if (isSubOpen[id]) {
      dispatch({ type: "RESET_SITE_SUB_NAV"});
    } 
    
    const handleScroll = () => {
      setScrollPos(
        typeof document !== "undefined" ? document.body.scrollTop : 0
      ); // TODO - Throttle to optimize
    };
    document.body.addEventListener("scroll", handleScroll);
    
    return () => {
    typeof document !== "undefined" &&
      document.body.removeEventListener("scroll", handleScroll);
    };
    
  }, []); 
  
  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('parallax-effect');
          observer.unobserve(entry.target); 
        } else {

        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      rootMargin: '0px',
      threshold: breakpoints.sm ? 0 : 0.1, // Adjust this value based on when you want the effect to trigger
    });

    // Add each parallax item to the observer
    const items = document.querySelectorAll('.parallax-item');
    items.forEach((item) => {
      observer.observe(item);
    });

    // Clean up the observer on component unmount
    return () => {
      items.forEach((item) => {
        observer.unobserve(item);
      });
    };
  }, [activeDisplay]); 


  useEffect(() => {
    const filteredTags = tags.filter((tag) => tag?.active); 
    setTagsList([{tag:"alleprojekte", active: true}, ...filteredTags]); 
  }, [tags]); 

  return (
    <Layout header={header} footer={footer} language={language} redirectTo={redirectTo} logosBgColor={logosBgColor}>
      <SEO
        websiteFontColor={websiteFontColor} 
        template="cases" 
        title={seoTitle} 
        description={seoDescription}
        keywords={seoKeywords} 
        darkTheme={darkMode || casesOverview.darkTheme}  
        websiteBackgroundColor={sectionBackgroundColor}
      />
      <SiteHeaderAlt header={header} redirectTo={redirectTo}>
        <div className="section section-mb section-small-margin" style={{backgroundColor: sectionBackgroundColor}}>
          {heading && (<Header heading={heading ? heading : ""} />)}
          {/* {tagsList && tagsList.length > 1 && (
            <div className={classNames("all-tags",{"all-tags-sticky": scrollPos > 0})}>
             <div className={classNames("container")}>
                {tagsList && tagsList.length > 1 && (
                    <div className="row">   
                      <div className="col-lg-11">
                         <div className="row">
                           <div className="col-md-9 d-flex">
                            <ul className="all-tags d-none">
                            {tagsList.map((tag, index)=> (
                              <li 
                                className={classNames("all-tags-item", {"all-tags-active": tag.tag === heading })}
                              >
                                <Link
                                  to={tag.tag === 'alleprojekte' ?  `/de/case-studies/` : `/de/tags/${tag.tag}/` }
                                  title={tag}
                                >
                                  {`#${tag.tag}`}
                                </Link>
                              </li>
                            ))}
                          </ul>
                          </div>
                          <div 
                            className={classNames("col-md-3 d-flex justify-content-end")}
                          >
                            <span className="switch-btn">
                              <ul 
                                className={classNames("box", {"active": activeDisplay === ""})}
                                onClick={() => handleClick("")}
                              >
                              <img src={box_view} />    
                              </ul>
                              <ul 
                                className={classNames("menu ", {"active": activeDisplay === "list"})}
                                onClick={() => handleClick("list")}
                              >
                                <img src={list_view} />    
                              </ul>
                            </span>
                          </div>  
                         </div>     
                      </div>                            
                    </div>
                )}
              </div>
            </div> 
          )} */}
          <span className="switch-btn switch-btn-layout">
            <ul 
              className={classNames("box", {"active": activeDisplay === ""})}
              onClick={() => handleClick("")}
            >
              <img src={box_view} />    
            </ul>
            <ul 
              className={classNames("menu ", {"active": activeDisplay === "list"})}
              onClick={() => handleClick("list")}
            >
              <img src={list_view} />    
           </ul>
          </span>
          <div className="container">
            <div className="row">
              <div className="offset-lg-1 col-lg-10">
                {activeDisplay ? (
                  <BlogList 
                    blogEntries={filteredCasesStudies} 
                    isCompact={false} 
                    isTagsDetailPage={true} 
                    activeDisplay={activeDisplay} 
                    showMobileImages={true}
                    showParallax={true}
                    imageBorderRadiusForServices={imageBorderRadiusForServices}
                    showPageTransition={true}
                    hashtagsBackgroundColor={displayedHashtagBgColor}
                    pageTransitionBgColor={pageTransitionBgColor}
                    sectionBackgroundColor={sectionBackgroundColor}
                  />
                ) : (
                  <CaseList 
                    caseStudies={filteredCasesStudies} 
                    showMobileImages={false}
                    oddElementMarginTop={true}
                    casesPage={true}
                    showParallax={true}
                    filter={tagsList[activeIndex]}
                    showfilter={true}
                    activeDisplay={activeDisplay}
                    imageBorderRadiusForServices={imageBorderRadiusForServices}
                    websiteFontColor={websiteFontColor}
                    showFullWidthOnMobile={true}
                    hashtagsBackgroundColor={displayedHashtagBgColor}
                    pageTransitionBgColor={pageTransitionBgColor}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </SiteHeaderAlt>
    </Layout>
  )
}

export const pageQuery = graphql`
  query CaseStudies($language: String!, $id: ID!) {
    allSitePage {
      nodes {
        path
      }
    }
    strapi {
      general {
        primaryBtn
        blogsOverviewHeadline
        clientLogosColorDarkMode
        clientLogosColorLightMode 
        imageBorderRadiusForServices
        greySectionLightModeBgColor
        greySectionDarkModeBgColor
        generalBackgroundColorDarkMode
        generalBackgroundColorLightMode
        fontColorDarkMode
        fontColorLightMode
        hashtagsBackgroundColor
        hashtagsBackgroundColorDarkMode
        pageTransitionBgColorLightMode
        pageTransitionBgColorDarkMode
      }
      headers(where: {language: $language}) {
        ...SiteHeader
      }
      footers(where: { language: $language }) {
        ...SiteFooter
      }
      casesOverview(id: $id) {
        copyright
        heading
        darkTheme
        webPages {
          about_us {
            language
          }
          contact {
            language
          }
          case_study {
            slug
            language
          }
          blogs_overview {
            language
          }
          cases_overview {
            language
          }
          careers_overview {
            language
          }	
          services_overview {
            language
          }
          blog {
            language
            slug
          }
          career {
            language
            slug
          }
          homepage {
            slug
            language
          }
          service_design {
            slug
            language
          }
          service_technical {
            slug
            language
          }
          service_casey_new {
            slug
            language
          }
          service_casey {
            slug
            language
          }
          plainpage {
            language
            slug
          }
        }
      }
      tags {
        tag
        active
      }
      caseStudies(where: { language: $language }, sort: "date:desc") {
       
        language
        inactive
        showCaseOnCasesOverview
        headerImageMobile {
          url
        }
        headerImage {
          url
        }
        clientLogo {
          logo {
            url
          }
        }
        title
        slug
        tags {
          tag
          active
        }
        previewImage {
          url 
        }
        description
        backgroundColor
        
      }

      casesPage {
        seo {
          metaData {
            metaTitle
            metaDescription
            keywords
          }
        }
      }
    }
  }
`

export default Cases
